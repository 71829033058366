setDarkMode();


function setDarkMode() {
    let toggleEl = document.querySelector('.dark__mode-toggle');
    let toggleIconEl = document.querySelector('.dark__mode-icon');
    if (localStorage.getItem('darkMode') == 'enabled') {
        toggleDarkMode(true);
    }

    toggleEl.addEventListener('click', () => {
        toggleDarkMode(localStorage.getItem('darkMode') == 'disabled' || localStorage.getItem('darkMode') == null);
    })



    function toggleDarkMode(shouldEnable) {
        if (shouldEnable == true) {
            toggleIconEl = document.querySelector('.dark__mode-icon');
            toggleIconEl.classList.remove('fa-moon');
            toggleIconEl.classList.add('fa-sun');
            document.body.classList.add('dark__mode');
            localStorage.setItem('darkMode', 'enabled');
        } else {
            toggleIconEl = document.querySelector('.dark__mode-icon');
            toggleIconEl.classList.remove('fa-sun');
            toggleIconEl.classList.add('fa-moon');
            document.body.classList.remove('dark__mode');
            localStorage.setItem('darkMode', 'disabled');
        }
    }
}